<template>
  <div class="register">
    <v-container class="custom-container">
      <v-stepper
        v-model="stepNo"
        alt-labels
      >
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-sheet
              rounded="sm"
              class="step1"
            >
              <div
                v-if="userLoggedIn"
                class="upper__content"
              >
                <h5>{{ $t('langkey.register_full_titel_1') }}</h5>
              </div>
              <div
                v-else
                class="upper__content"
              >
                <h5>
                  <b>{{ $t('langkey.join-now') }}</b>
                </h5>
                <h5 v-html="$t('langkey.register-form-intro-1')" />
                <p class="mt-3">
                  {{ $t('langkey.register-form-intro-2') }}
                </p>
              </div>

              <v-divider v-if="!userLoggedIn" />

              <div class="lower__content">
                <p class="remark">
                  * = {{ $t('langkey.mandatory-entry-fields') }}
                </p>

                <form
                  class="content__wrapper"
                  name="register-form-address"
                >
                  <div class="address">
                    <p class="section">
                      {{ $t('langkey.address-data') }}
                    </p>

                    <p class="label">
                      {{ $t('langkey.company-name') }} <span
                        v-if="inputStepOne.businessName.required"
                        class="required"
                      >*</span> :
                    </p>
                    <v-text-field
                      v-model="inputStepOne.businessName.value"
                      :error-messages="[errorMessageInputStepOne.businessName]"
                      :label="`${$t('langkey.enter')} ${$t('langkey.company-name')}`"
                      :class="[
                        errorMessageInputStepOne.businessName !== '' ? 'show-error' : null
                      ]"
                      name="register-form-address-company-name-input"
                      solo
                      @input="errorMessageInputStepOne.businessName = ''"
                    />

                    <p class="label">
                      {{ $t('langkey.address') }} <span
                        v-if="inputStepOne.address.required"
                        class="required"
                      >*</span> :
                    </p>
                    <v-text-field
                      v-model="inputStepOne.address.value"
                      :label="`${$t('langkey.enter')} ${$t('langkey.address')}`"
                      :error-messages="[errorMessageInputStepOne.address]"
                      :class="[
                        errorMessageInputStepOne.address !== '' ? 'show-error' : null
                      ]"
                      name="register-form-address-address-input"
                      solo
                      @input="errorMessageInputStepOne.address = ''"
                    />

                    <v-row>
                      <v-col md="4">
                        <p class="label">
                          {{ $t('langkey.post-code') }} <span
                            v-if="inputStepOne.zipCode.required"
                            class="required"
                          >*</span> :
                        </p>
                        <v-text-field
                          v-model.number="inputStepOne.zipCode.value"
                          :label="`${$t('langkey.enter')} ${$t('langkey.post-code')}`"
                          :error-messages="[errorMessageInputStepOne.zipCode]"
                          :class="[
                            errorMessageInputStepOne.zipCode !== '' ? 'show-error' : null
                          ]"
                          name="register-form-address-zipcode-input"
                          type="number"
                          solo
                          @input="errorMessageInputStepOne.zipCode = ''"
                        />
                      </v-col>
                      <v-col md="8">
                        <p class="label">
                          {{ $t('langkey.place') }} <span
                            v-if="inputStepOne.city.required"
                            class="required"
                          >*</span> :
                        </p>
                        <v-text-field
                          v-model="inputStepOne.city.value"
                          :label="`${$t('langkey.enter')} ${$t('langkey.place')}`"
                          :error-messages="[errorMessageInputStepOne.city]"
                          :class="[
                            errorMessageInputStepOne.city !== '' ? 'show-error' : null
                          ]"
                          name="register-form-address-city-input"
                          solo
                          @input="errorMessageInputStepOne.city = ''"
                        />
                      </v-col>
                    </v-row>

                    <p class="label">
                      {{ $t('langkey.country') }} <span
                        v-if="inputStepOne.country.required"
                        class="required"
                      >*</span> :
                    </p>
                    <v-select
                      v-model="inputStepOne.country.value"
                      :items="[{ label: 'Germany (Deutschland)', value: 'DE' }]"
                      item-text="label"
                      item-value="value"
                      :label="`${$t('langkey.enter')} ${$t('langkey.country')}`"
                      :error-messages="[errorMessageInputStepOne.country]"
                      name="register-form-address-country-input"
                      solo
                      disabled
                      @input="errorMessageInputStepOne.country = ''"
                    >
                      <template #append>
                        <icon-caret />
                      </template>
                    </v-select>

                    <p class="label">
                      {{ $t('langkey.website') }} <span
                        v-if="inputStepOne.website.required"
                        class="required"
                      >*</span> :
                    </p>
                    <v-text-field
                      v-model="inputStepOne.website.value"
                      :label="`${$t('langkey.enter')} ${$t('langkey.website')}`"
                      :error-messages="[errorMessageInputStepOne.website]"
                      :class="[
                        errorMessageInputStepOne.website !== '' ? 'show-error' : null
                      ]"
                      name="register-form-address-website-input"
                      solo
                      @input="errorMessageInputStepOne.website = ''"
                    />
                  </div>
                  <div class="contact">
                    <p class="section">
                      {{ $t('langkey.contact') }}
                    </p>

                    <v-row>
                      <v-col md="3">
                        <p class="label">
                          {{ $t('langkey.salutation') }} <span
                            v-if="inputStepOne.gender.required"
                            class="required"
                          >*</span> :
                        </p>
                        <v-select
                          v-model="inputStepOne.gender.value"
                          :items="genders"
                          :error-messages="[errorMessageInputStepOne.gender]"
                          :class="[
                            errorMessageInputStepOne.gender !== '' ? 'show-error' : null
                          ]"
                          name="register-form-address-gender-input"
                          item-text="label"
                          item-value="value"
                          solo
                          @input="errorMessageInputStepOne.gender = ''"
                        >
                          <template #append>
                            <icon-caret />
                          </template>
                        </v-select>
                      </v-col>
                      <v-col>
                        <p class="label">
                          {{ $t('langkey.contact-person') }} <span
                            v-if="inputStepOne.firstname.required"
                            class="required"
                          >*</span> :
                        </p>
                        <v-text-field
                          v-model="inputStepOne.firstname.value"
                          :label="`${$t('langkey.enter')} ${$t('langkey.first-name')}`"
                          :error-messages="[errorMessageInputStepOne.firstname]"
                          :class="[
                            errorMessageInputStepOne.firstname !== '' ? 'show-error' : null
                          ]"
                          name="register-form-address-firstname-input"
                          solo
                          @input="errorMessageInputStepOne.firstname = ''"
                        />
                      </v-col>
                      <v-col>
                        <p class="label">
                          <br>
                        </p>
                        <v-text-field
                          v-model="inputStepOne.lastname.value"
                          :label="`${$t('langkey.enter')} ${$t('langkey.last-name')}`"
                          :error-messages="[errorMessageInputStepOne.lastname]"
                          :class="[
                            errorMessageInputStepOne.lastname !== '' ? 'show-error' : null
                          ]"
                          name="register-form-address-lastname-input"
                          solo
                          @input="errorMessageInputStepOne.lastname = ''"
                        />
                      </v-col>
                    </v-row>

                    <p class="label">
                      {{ $t('langkey.phone') }} <span
                        v-if="inputStepOne.phone.required"
                        class="required"
                      >*</span> :
                    </p>
                    <v-text-field
                      v-model="inputStepOne.phone.value"
                      :label="`${$t('langkey.enter')} ${$t('langkey.phone')}`"
                      :error-messages="[errorMessageInputStepOne.phone]"
                      :class="[
                        errorMessageInputStepOne.phone !== '' ? 'show-error' : null
                      ]"
                      name="register-form-address-phone-input"
                      solo
                      @input="errorMessageInputStepOne.phone = ''"
                    />

                    <p class="label">
                      {{ $t('langkey.fax') }} <span
                        v-if="inputStepOne.fax.required"
                        class="required"
                      >*</span> :
                    </p>
                    <v-text-field
                      v-model="inputStepOne.fax.value"
                      :label="`${$t('langkey.enter')} ${$t('langkey.fax')}`"
                      :error-messages="[errorMessageInputStepOne.fax]"
                      :class="[
                        errorMessageInputStepOne.fax !== '' ? 'show-error' : null
                      ]"
                      name="register-form-address-fax-input"
                      solo
                      @input="errorMessageInputStepOne.fax = ''"
                    />

                    <p class="label">
                      {{ $t('langkey.email') }} <span
                        v-if="inputStepOne.mail.required"
                        class="required"
                      >*</span> :
                    </p>
                    <v-text-field
                      v-model="inputStepOne.mail.value"
                      :label="`${$t('langkey.enter')} ${$t('langkey.email')}`"
                      :error-messages="[errorMessageInputStepOne.mail]"
                      :class="[
                        errorMessageInputStepOne.mail !== '' ? 'show-error' : null
                      ]"
                      name="register-form-address-mail-input"
                      type="mail"
                      solo
                      @input="errorMessageInputStepOne.mail = ''"
                    />

                    <div class="agreement-checkbox">
                      <p
                        class="section checkbox-header"
                        v-html="`${$t('langkey.agree-to-terms-conditions-header')} *`"
                      />
                      <label
                        class="checkbox"
                        style="margin-bottom: 6px;"
                      >
                        <base-checkbox
                          v-model="isAgree"
                          :class="[
                            isAgreeError ? 'show-error' : null
                          ]"
                          reverse
                          class="checkbox-item"
                          @input="isAgreeError = false"
                        >
                          <template #custom-text>
                            <span
                              class="terms-conditions"
                              v-html="$t('langkey.agree-to-terms-conditions')"
                            />
                          </template>
                        </base-checkbox>
                      </label>

                      <template>
                        <transition
                          name="page"
                          mode="out-in"
                        >
                          <p
                            v-if="isAgreeError"
                            class="error-message v-messages__message"
                            style="font-size: 0.8571428571rem; color: #de0000; padding-left: 26px; margin-top: 6px;"
                          >
                            {{ $t('langkey.mandatory-entry-fields-test-and-unlimited-access') }}
                          </p>
                        </transition>
                      </template>

                      <p
                        class="section checkbox-header"
                        v-html="$t('langkey.register-accept-update-header')"
                      />
                      <label class="checkbox">
                        <base-checkbox
                          v-model="isCommercial"
                          reverse
                          class="checkbox-item"
                        >
                          <template #custom-text>
                            <span>
                              {{ $t('langkey.register-accept-update-text') }}
                            </span>
                          </template>
                        </base-checkbox>
                      </label>
                    </div>
                  </div>
                  <div class="step__footer">
                    <v-btn
                      depressed
                      color="accent"
                      class="step__button"
                      @click="stepOneValidate"
                    >
                      {{ $t('langkey.continue') }} <icon-arrow />
                    </v-btn>
                  </div>
                </form>
              </div>
            </v-sheet>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-sheet
              rounded="sm"
              class="step2"
            >
              <h5
                v-if="!userLoggedIn"
                v-html="$t('langkey.registration-data')"
              />
              <h5
                v-else
                v-html="$t('langkey.register_full_title')"
              />
              <p class="remark">
                * = {{ $t('langkey.mandatory-entry-fields-unlimited-access') }}
              </p>

              <div class="company-data">
                <v-row>
                  <v-col cols="6">
                    <p class="label">
                      {{ $t('langkey.sale-tax-id') }} <span
                        v-if="inputStepTwo.taxIdentificationNumber.required"
                        class="required"
                      >*</span> :
                    </p>
                    <v-text-field
                      v-model="inputStepTwo.taxIdentificationNumber.value"
                      :error-messages="[errorMessageInputStepTwo.taxIdentificationNumber]"
                      :class="[
                        errorMessageInputStepTwo.taxIdentificationNumber !== '' ? 'show-error' : null
                      ]"
                      :label="`${$t('langkey.enter')} ${$t('langkey.sale-tax-id')}`"
                      name="register-form-company-data-taxid-input"
                      solo
                      @input="errorMessageInputStepTwo.taxIdentificationNumber = ''"
                    />
                  </v-col>
                </v-row>
              </div>

              <div class="bank-data">
                <p class="section">
                  {{ $t('langkey.further-company-data') }}
                </p>

                <v-row>
                  <v-col
                    class="left"
                    cols="6"
                  >
                    <p class="label">
                      {{ $t('langkey.account-owner') }} <span
                        v-if="inputStepTwo.bankAccountHolder.required"
                        class="required"
                      >*</span> :
                    </p>
                    <v-text-field
                      v-model="inputStepTwo.bankAccountHolder.value"
                      :label="`${$t('langkey.enter')} ${$t('langkey.account-owner')}`"
                      :error-messages="[errorMessageInputStepTwo.bankAccountHolder]"
                      :class="[
                        errorMessageInputStepTwo.bankAccountHolder !== '' ? 'show-error' : null
                      ]"
                      name="register-form-company-data-account-owner-input"
                      solo
                      @input="errorMessageInputStepTwo.bankAccountHolder = ''"
                    />

                    <p class="label">
                      {{ $t('langkey.bank-name') }} <span
                        v-if="inputStepTwo.bankName.required"
                        class="required"
                      >*</span> :
                    </p>
                    <v-text-field
                      v-model="inputStepTwo.bankName.value"
                      :error-messages="[errorMessageInputStepTwo.bankName]"
                      :class="[
                        errorMessageInputStepTwo.bankName !== '' ? 'show-error' : null
                      ]"
                      :label="`${$t('langkey.enter')} ${$t('langkey.bank-name')}`"
                      name="register-form-company-data-bank-name-input"
                      solo
                      @input="errorMessageInputStepTwo.bankName = ''"
                    />

                    <p class="label">
                      {{ $t('langkey.iban') }} <span
                        v-if="inputStepTwo.bankIban.required"
                        class="required"
                      >*</span> :
                    </p>
                    <v-text-field
                      v-model="inputStepTwo.bankIban.value"
                      :label="`${$t('langkey.enter')} ${$t('langkey.iban')}`"
                      :error-messages="[errorMessageInputStepTwo.bankIban]"
                      :class="[
                        errorMessageInputStepTwo.bankIban !== '' ? 'show-error' : null
                      ]"
                      name="register-form-company-data-bank-iban-input"
                      solo
                      @input="errorMessageInputStepTwo.bankIban = ''"
                    />

                    <p class="label">
                      {{ $t('langkey.bic') }} <span
                        v-if="inputStepTwo.bankBic.required"
                        class="required"
                      >*</span> :
                    </p>
                    <v-text-field
                      v-model="inputStepTwo.bankBic.value"
                      :label="`${$t('langkey.enter')} ${$t('langkey.bic')}`"
                      :error-messages="[errorMessageInputStepTwo.bankBic]"
                      :class="[
                        errorMessageInputStepTwo.bankBic !== '' ? 'show-error' : null
                      ]"
                      name="register-form-company-data-bank-bic-input"
                      solo
                      @input="errorMessageInputStepTwo.bankBic = ''"
                    />

                    <p class="label">
                      {{ $t('langkey.sepa-full') }} <span
                        v-if="inputStepTwo.bankSepaAuthorized.required"
                        class="required"
                      >*</span> :
                    </p>
                    <v-text-field
                      v-model="inputStepTwo.bankSepaAuthorized.value"
                      :label="`${$t('langkey.enter')} ${$t('langkey.sepa-full')}`"
                      :error-messages="[errorMessageInputStepTwo.bankSepaAuthorized]"
                      :class="[
                        errorMessageInputStepTwo.bankSepaAuthorized !== '' ? 'show-error' : null
                      ]"
                      name="register-form-company-data-bank-sepa-input"
                      solo
                      @input="errorMessageInputStepTwo.bankSepaAuthorized = ''"
                    />
                  </v-col>
                  <v-col
                    class="right"
                    cols="6"
                  >
                    <p
                      class="section checkbox-header"
                      v-html="`${$t('langkey.sepa-direct-debit-mandate')} *`"
                    />
                    <label
                      class="checkbox"
                      style="margin-bottom: 6px;"
                    >
                      <base-checkbox
                        v-model="isSepaGlobalApproval"
                        :class="[
                          isSepaGlobalApprovalError ? 'show-error' : null
                        ]"
                        reverse
                        class="checkbox-item"
                        @input="isSepaGlobalApprovalError = false"
                      >
                        <template #custom-text>
                          <span>
                            {{ $t('langkey.grant-sepa-authorization') }}
                          </span>
                        </template>
                      </base-checkbox>
                    </label>

                    <transition
                      name="page"
                      mode="out-in"
                    >
                      <p
                        v-if="isSepaGlobalApprovalError"
                        style="font-size: 0.8571428571rem; color: #de0000; padding-left: 36px;"
                      >
                        {{ $t('langkey.mandatory-entry-fields-unlimited-access') }}
                      </p>
                    </transition>

                    <p
                      v-if="!isCommercial && stepNo === 2"
                      class="section checkbox-header"
                      v-html="$t('langkey.register-accept-update-header')"
                    />
                    <label
                      v-if="!isCommercial && stepNo === 2"
                      class="checkbox"
                    >
                      <base-checkbox
                        v-model="isCommercial"
                        reverse
                        class="checkbox-item"
                      >
                        <template #custom-text>
                          <span>
                            {{ $t('langkey.register-accept-update-text') }}
                          </span>
                        </template>
                      </base-checkbox>
                    </label>

                    <div 
                      v-if="userLoggedIn"
                      class="button__wrapper"
                    >
                      <v-btn
                        depressed
                        color="accent"
                        class="step__button next full-access"
                        @click="register('unlimited')"
                      >
                        <!-- @click="stepNo = 3" -->
                        <span
                          v-html="$t('langkey.upgrade-to-full-access')"
                        />
                        <icon-arrow color="var(--theme-black)" />
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div
                v-if="!userLoggedIn"
                class="step__footer"
              >
                <div class="button__wrapper">
                  <p>
                    <span v-html="$t('langkey.take-a-look-access')" />
                    <a
                      @click="register('test')"
                    >
                      {{ $t('langkey.test-account') }}
                    </a>
                  </p>

                  <v-btn
                    outlined
                    class="step__button back"
                    @click="stepNo = 1"
                  >
                    <icon-arrow color="var(--theme-black)" /> {{ $t('langkey.back') }}
                  </v-btn>

                  <v-btn
                    depressed
                    color="accent"
                    class="step__button next full-access"
                    @click="register('unlimited')"
                  >
                    <!-- @click="stepNo = 3" -->
                    {{ $t('langkey.get-unlimited-access') }}
                    <icon-arrow color="var(--theme-black)" />
                  </v-btn>
                </div>
              </div>
            </v-sheet>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-sheet
              rounded="sm"
              class="step3"
            >
              <h3 class="success-title">
                {{ $t('langkey.registration-successful') }}
              </h3>

              <p
                v-if="!userLoggedIn"
                class="thanks mt-3"
                v-html="$t('langkey.thanks-for-registration-request')"
              />

              <p
                v-else
                class="thanks mt-3"
                v-html="$t('langkey.SEPA-request-sent')"
              />

              <div class="button__wrapper">
                <v-btn
                  v-if="!userLoggedIn"
                  outlined
                  class="step__button back"
                  @click="onResetForm"
                >
                  {{ $t('langkey.back') }}
                </v-btn>
              </div>
            </v-sheet>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>
  </div>
</template>

<style lang="scss">
@import '@/styles/pages/register.scss';
</style>

<script>
import allCountries from '@/constants/countries';
import IconCaret from '@/components/icons/IconCaret';
import IconArrow from '@/components/icons/IconArrow';
import {mapState} from 'vuex';
import {getCookie} from '@/utils/js-cookie';
import _ from 'lodash';

export default {
  components: {
    IconCaret,
    IconArrow,
  },
  data () {
    return {
      stepNo: 1,
      genders: [
        {
          label: this.$t('langkey.male'),
          value: 'male',
        },
        {
          label: this.$t('langkey.female'),
          value: 'female',
        },
        {
          label: this.$t('langkey.salutation.misscellaneous'),
          value: 'diverse',
        }],
      inputStepOne: {
        businessName: {
          required: true,
          value: '',
        },
        gender: {
          required: true,
          value: '',
        },
        firstname: {
          required: true,
          value: '',
        },
        lastname: {
          required: true,
          value: '',
        },
        phone: {
          required: true,
          value: '',
        },
        address: {
          required: true,
          value: '',
        },
        fax: {
          required: false,
          value: '',
        },
        zipCode: {
          required: true,
          value: '',
        },
        city: {
          required: true,
          value: '',
        },
        mail: {
          required: true,
          value: '',
        },
        country: {
          required: false,
          value: 'DE',
        },
        website: {
          required: false,
          value: '',
        },
      },
      errorMessageInputStepOne: {
        businessName: '',
        gender: '',
        firstname: '',
        lastname: '',
        phone: '',
        address: '',
        fax: '',
        zipCode: '',
        city: '',
        mail: '',
        country: '',
        website: '',
      },
      inputStepTwo: {
        taxIdentificationNumber: {
          required: false,
          value: '',
        },
        bankAccountHolder: {
          required: true,
          value: '',
        },
        companyFax: {
          required: false,
          value: '',
        },
        companyWebsite: {
          required: false,
          value: '',
        },
        bankName: {
          required: true,
          value: '',
        },
        bankBic: {
          required: true,
          value: '',
        },
        bankIban: {
          required: true,
          value: '',
        },
        bankSepaAuthorized: {
          required: true,
          value: '',
        },
      },
      errorMessageInputStepTwo: {
        taxIdentificationNumber: '',
        bankAccountHolder: '',
        companyFax: '',
        companyWebsite: '',
        bankName: '',
        bankBic: '',
        bankIban: '',
        bankSepaAuthorized: '',
      },
      isCommercial: false,
      isAgree: false,
      isAgreeError: false,
      isSepaGlobalApproval: false,
      isSepaGlobalApprovalError: false,
    };
  },
  computed: {
    ...mapState({
      userInformation: (state) => state.user.userInformation,
      customerInformation: (state) => state.registration.customerInformation,
    }),
    hasErrorStepOne() {
      return Object.values(this.errorMessageInputStepOne).some((val) => val !== '') || this.isAgreeError;
    },
    hasErrorStepTwo() {
      return Object.values(this.errorMessageInputStepTwo).some((val) => val !== '');
    },
    countryOptions() {
      return allCountries;
    },
    userLoggedIn() {
      return getCookie('user_access_token');
    },
  },
  watch: {
    customerInformation(val) {
      if(this.userLoggedIn) {
        this.setInputValues();
      }
    },
  },
  created() {
    if (this.userLoggedIn) {
      this.$store.dispatch('registration/getCustomerInformation');
    }
  },
  mounted() {
    if(this.userLoggedIn) {
      this.setInputValues();
    }
  },
  methods: {
    setInputValues() {
      this.inputStepTwo.taxIdentificationNumber.required = false;

      let customerInformation = !_.isEmpty(this.customerInformation) ?
        this.customerInformation :
        JSON.parse(sessionStorage.getItem('customerInformation'));

      if (customerInformation) {
        this.inputStepOne.businessName.value = customerInformation.businessName;
        this.inputStepOne.gender.value = customerInformation.contactPersons[0].gender;
        this.inputStepOne.firstname.value = customerInformation.contactPersons[0].firstname;
        this.inputStepOne.lastname.value = customerInformation.contactPersons[0].lastname;
        this.inputStepOne.phone.value = customerInformation.contactPersons[0].phone;
        this.inputStepOne.address.value = customerInformation.address;
        this.inputStepOne.fax.value = customerInformation.contactPersons[0].fax;
        this.inputStepOne.zipCode.value = customerInformation.zipCode;
        this.inputStepOne.city.value = customerInformation.city;
        this.inputStepOne.mail.value = customerInformation.contactPersons[0].mail;
        this.inputStepOne.country.value = customerInformation.country;
        this.inputStepOne.website.value = customerInformation.website;

        this.inputStepTwo.taxIdentificationNumber.value = customerInformation.taxIdentificationNumber;
        this.inputStepTwo.bankAccountHolder.value = customerInformation.bankAccountHolder;
        this.inputStepTwo.bankName.value = customerInformation.bankName;
        this.inputStepTwo.bankIban.value = customerInformation.bankIban;
        this.inputStepTwo.bankBic.value = customerInformation.bankBic;
        this.inputStepTwo.bankSepaAuthorized.value = customerInformation.bankSepaAuthorized;
      }
    },
    stepOneValidate () {
      Object.keys(this.inputStepOne).forEach((key) => {
        if (this.inputStepOne[key].required && (this.inputStepOne[key].value === '' || !this.inputStepOne[key].value)) {
          this.errorMessageInputStepOne = {
            ...this.errorMessageInputStepOne,
            [key]: this.$t('langkey.mandatory-entry-fields'),
          };
        } else if (key === 'mail' && !(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.inputStepOne[key].value))) {
          this.errorMessageInputStepOne = {
            ...this.errorMessageInputStepOne,
            [key]: 'Sie haben eine ungültige Email-Adresse eingegeben!',
          };
        }
      });

      if (!this.isAgree) {
        this.isAgreeError = true;
        return;
      }

      if (this.hasErrorStepOne) {
        return;
      }
      
      this.stepNo = 2;
    },
    stepTwoValidateTypeTest() {
      this.inputStepTwo.bankAccountHolder.required = false;
      this.inputStepTwo.bankName.required = false;
      this.inputStepTwo.bankBic.required = false;
      this.inputStepTwo.bankIban.required = false;
      this.inputStepTwo.bankSepaAuthorized.required = false;
      Object.keys(this.inputStepTwo).forEach((key) => {
        if (key === 'taxIdentificationNumber' && this.inputStepTwo[key].value !== '' && !this.validateTaxIdentificationNumber()) {
          this.errorMessageInputStepTwo = {
            ...this.errorMessageInputStepTwo,
            [key]: this.$t('langkey.taxidentification-alpha-numeric-error'),
          };
        } else if (this.inputStepTwo[key].required && this.inputStepTwo[key].value === '') {
          this.errorMessageInputStepTwo = {
            ...this.errorMessageInputStepTwo,
            [key]: this.$t('langkey.mandatory-entry-fields'),
          };
        } else {
          this.errorMessageInputStepTwo = {
            ...this.errorMessageInputStepTwo,
            [key]: '',
          };
        }
      });
    },
    stepTwoValidateTypeFull() {
      this.inputStepTwo.bankAccountHolder.required = true;
      this.inputStepTwo.bankName.required = true;
      this.inputStepTwo.bankBic.required = true;
      this.inputStepTwo.bankIban.required = true;
      this.inputStepTwo.bankSepaAuthorized.required = true;
      Object.keys(this.inputStepTwo).forEach((key) => {
        if (key === 'taxIdentificationNumber' && this.inputStepTwo[key].value !== '' && !this.validateTaxIdentificationNumber()) {
          this.errorMessageInputStepTwo = {
            ...this.errorMessageInputStepTwo,
            [key]: this.$t('langkey.taxidentification-alpha-numeric-error'),
          };
        } else if (this.inputStepTwo[key].required && this.inputStepTwo[key].value === '') {
          this.errorMessageInputStepTwo = {
            ...this.errorMessageInputStepTwo,
            [key]: this.$t('langkey.mandatory-entry-fields-unlimited-access'),
          };
        } else {
          this.errorMessageInputStepTwo = {
            ...this.errorMessageInputStepTwo,
            [key]: '',
          };
        }
      });
    },
    async register(type = 'test') {
      if (type === 'test') {
        this.stepTwoValidateTypeTest();
      } else {
        this.stepTwoValidateTypeFull();
      }

      if (type === 'test' && this.hasErrorStepTwo) {
        if (this.errorMessageInputStepTwo.taxIdentificationNumber) {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: this.$t('langkey.error-register-alpha-numeric'),
            message: this.$t('langkey.taxidentification-alpha-numeric-error'),
          });
        } else {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: this.$t('langkey.error-register'),
            message: this.$t('langkey.please-fill-mandatory-field'),
          });
        }
        if (!this.isSepaGlobalApproval) {
          this.isSepaGlobalApprovalError = false;
        }
        return;
      }

      if (type === 'unlimited' && this.hasErrorStepTwo) {
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: this.$t('langkey.error-register'),
          message: this.$t('langkey.please-fill-mandatory-field'),
        });
        if (!this.isSepaGlobalApproval) {
          this.isSepaGlobalApprovalError = true;
        }
        return;
      }

      if (!this.isSepaGlobalApproval) {
        if (type === 'unlimited') {
          this.isSepaGlobalApprovalError = true;
        } else {
          this.isSepaGlobalApprovalError = false;
        }
      }

      if (!this.isSepaGlobalApproval) {
        if (type === 'unlimited') {
          this.isSepaGlobalApprovalError = true;
          return;
        } else {
          this.isSepaGlobalApprovalError = false;
        }
      }

      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      const contactPersonPayload = [{
        gender: this.inputStepOne.gender.value,
        firstname: this.inputStepOne.firstname.value,
        lastname: this.inputStepOne.lastname.value,
        phone: this.inputStepOne.phone.value,
        fax: this.inputStepOne.fax.value,
        mail: this.inputStepOne.mail.value,
      }];
      let payload = {};
      Object.keys(this.inputStepOne).forEach((key) => {
        payload = {
          ...payload,
          [key]: this.inputStepOne[key].value,
        };
      });
      Object.keys(this.inputStepTwo).forEach((key) => {
        payload = {
          ...payload,
          [key]: this.inputStepTwo[key].value,
        };
      });
      try {
        await this.$store.dispatch(
          this.userLoggedIn ? 'registration/upgrade' : 'registration/register',
          {
            ...payload,
            type: type === 'test' ? 'account.type.demo' : 'account.type.full',
            contactPersons: contactPersonPayload,
            sepaGlobalApproval: this.isSepaGlobalApproval,
            marketingApproved: this.isCommercial,
          });
        this.stepNo = 3;
      } catch (err) {
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: this.$t('langkey.error-register'),
          message: err,
        });
      } finally {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
      }
    },
    onResetForm() {
      this.stepNo = 1;
      this.inputStepOne = {
        businessName: {
          required: true,
          value: '',
        },
        gender: {
          required: true,
          value: '',
        },
        firstname: {
          required: true,
          value: '',
        },
        lastname: {
          required: true,
          value: '',
        },
        phone: {
          required: true,
          value: '',
        },
        address: {
          required: true,
          value: '',
        },
        fax: {
          required: false,
          value: '',
        },
        zipCode: {
          required: true,
          value: '',
        },
        city: {
          required: true,
          value: '',
        },
        mail: {
          required: true,
          value: '',
        },
        country: {
          required: false,
          value: 'DE',
        },
        website: {
          required: false,
          value: '',
        },
      };
      this.errorMessageInputStepOne = {
        businessName: '',
        gender: '',
        firstname: '',
        lastname: '',
        phone: '',
        address: '',
        fax: '',
        zipCode: '',
        city: '',
        mail: '',
        country: '',
        website: '',
      };
      this.inputStepTwo = {
        taxIdentificationNumber: {
          required: false,
          value: '',
        },
        bankAccountHolder: {
          required: false,
          value: '',
        },
        companyFax: {
          required: false,
          value: '',
        },
        companyWebsite: {
          required: false,
          value: '',
        },
        bankName: {
          required: false,
          value: '',
        },
        bankBic: {
          required: false,
          value: '',
        },
        bankIban: {
          required: false,
          value: '',
        },
        bankSepaAuthorized: {
          required: false,
          value: '',
        },
      };
      this.errorMessageInputStepTwo = {
        taxIdentificationNumber: '',
        // tradeRegisterNumber: '',
        bankAccountHolder: '',
        companyFax: '',
        companyWebsite: '',
        bankName: '',
        bankBic: '',
        bankIban: '',
        bankSepaAuthorized: '',
      };
      this.isCommercial = false;
      this.isAgree = false;
      this.isSepaGlobalApproval = false;
    },
    validateTaxIdentificationNumber() {
      let letterNumber = /^[0-9a-zA-Z]+$/;
      if ((this.inputStepTwo.taxIdentificationNumber.value.match(letterNumber))) {
        return true;
      } else {
        return false; 
      }
    },
  },
};
</script>
